<template>
    <span>
        <Tag class="mr-2" v-if="status > alert"  severity="success" :value="status"></Tag>
        <!--Tag class="mr-2" v-if="status === 'STARTRENEW'"  severity="info" value="EN PROCESO"></Tag-->
        <Tag class="mr-2" v-if="status < alert && status > critic"  severity="warning" :value="status"></Tag>
        <!--Tag class="mr-2" v-if="status === 'RENEW'"  severity="success" value="ACTUALIZADO" style="background-color: grey;"></Tag-->
        <Tag severity="danger" v-if="status < critic" :value="status"></Tag>
        <!--Tag severity="danger" v-if="status === 'REJECTED' || status === 'REJECT'" value="RECHAZADO"></Tag-->
    </span>
</template>

<script>
   
    let StatusTag = {};
    (function () {

        this.name = 'status-tag';

        this.created = function () {

        };
        this.data = function () {
            return {
            }
        };
        this.methods = {

        };
        this.mounted = function () {};
        this.components = {};
        this.computed = {};
        this.watch = {};
        this.props = ['status', 'alert', 'critic'];
    }).apply(StatusTag);
    export default StatusTag;
</script>


<style scoped>
  

</style>

