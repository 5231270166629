<template id="home">
    <div class="card p-fluid">

    <h5 v-if="renovar= false" class="text-black">NUEVO MANTENEMIENTO VEHICULO</h5>
    <h5 v-else class="text-black">RENOVACION MANTENEMIENTO VEHICULO</h5>

    <div class="grid m-3">
        <div class="col-12 md:col-12 lg:col-4">
        </div> 
        <div class="col-12 md:col-12 lg:col-4">
            <autoComplete id="vehicle_selector_id" v-model="selectedVehicle" field="plate" :suggestions="filteredVehicleMultiple" @complete="searchVehicleMultiple($event)" placeholder="Seleccione vehiculo" :disabled="renovacion"></autoComplete>
        </div>
        <div class="col-12 md:col-12 lg:col-4">
           
        </div> 

        <div class="col-12 md:col-12 lg:col-4">
           
        </div> 
        <div class="col-12 md:col-12 lg:col-4" v-if="selectedVehicle != null">
            <p v-if="selectedVehicle.last_medition_km_date != undefined"> Ultima Medicion: {{getLocalDates(selectedVehicle.last_medition_km_date)}}</p>
            <p> Kilometraje Actual: {{selectedVehicle.kilometers}} KM</p>
        </div> 
        <div class="col-12 md:col-12 lg:col-4" v-else>
            <p> Ultima Medicion: <strong>SELECCIONE VEHICULO</strong></p>
            <p> Kilometraje Actual: <strong>SELECCIONE VEHICULO</strong></p>
        </div> 

        
        <div class="col-12 md:col-12 lg:col-4">
           
        </div> 

 <div class="col-12 md:col-12 lg:col-4">
           
        </div> 
        <div class="col-12 md:col-12 lg:col-4"  v-if="selectedVehicle != null">
            
            <!--autoComplete id="maint_type_selector_id" v-model="selectedMaintType" field="name" :suggestions="filteredMaintTypeMultiple" @complete="searchMaintTypeMultiple($event)" placeholder="Seleccione tipo mantenimiento" :disabled="renovacion"></autoComplete-->

            <DropdownPrime id="otm_type" v-model="selectedMaintType" :options="all_maint_type" optionLabel="name" />

            <p>Cada cuanto es el mantenimiento?: <InputNumber v-model="kilometrs_times"/></p>

            <h5 class="text-black">Fecha de Mantenimeinto</h5>

            <Calendar id="IdCalendarMentenimiento" v-model="mainte_date" :showTime="true" dateFormat="dd/mm/yy" />

            <p>Kilometraje al realizar mantenimiento: <InputNumber v-model="kilometrs_maintenence"/></p>

            <p> Seleccione Turno del Mantenimiento:</p>
            <div class="field-radiobutton">
                <Radiobutton inputId="worshift1" name="DIA" value="DIA" v-model="select_turno" />
                <label for="worshift1">Dia</label>
            </div>
            <div class="field-radiobutton">
                <Radiobutton inputId="workshift2" name="NOCHE" value="NOCHE" v-model="select_turno" />
                <label for="workshift2">Noche</label>
            </div>

            <!--p> Nuevo Kilometraje (opcional): <InputNumber v-model="new_kilometrs"/></p-->

            <p> OT ID (opcional): <InputText type="text" v-model="otm_id"/></p>
            
            <p> Observaciones (opcional): <InputText type="text" v-model="obs"/></p>

            <buttonp v-if="renovacion == false" class="w-6 mt-3 p-button-warning" label="Ingrese Mantenimiento" @click.prevent="newMan()"/>

            <buttonp v-else class="w-6 mt-3 p-button-warning" label="RENOVAR Mantenimiento" @click.prevent="renovarr()"/>

        </div>
         <div class="col-12 md:col-12 lg:col-4">
           
        </div> 

    </div>

    <br /><br /><br /><br /><br /><br /><br />
    </div>
</template>


<script>
    import VehicleService from '@/service/VehicleService';
    import MaintenanceService from '@/service/MaintenanceService';
    import {mapGetters} from 'vuex';
    import moment from "moment";
    
    let NewMaintenanceVehicle = {};
    (function () {
        this.name = 'update-kilometers-vehicle-page';
        // let _self = null;

        this.created = function () {
            this.getAllVehicle();
            this.getAllMaintenanceType();
            if(this.$route.params.id_vehicle && this.$route.params.id_type && this.$route.params.maint_id){
                    this.renovacion = true;
            }
            
        };

        this.mounted  = function () {
            window.scrollTo(0, 0);
        };

        this.data = function () {
            return {
               selectedVehicle: null,
               all_vehicle: [],
               filteredVehicleMultiple: [],
               all_maint_type: [],
               selectedMaintType: null,
               filteredMaintTypeMultiple: [],
               kms: [],
               obs:null,
               new_kilometrs: null,
               otm_id: null,
               select_turno: null,
               renovacion: false,
               mainte_date: null,
               kilometrs_maintenence: null,
               kilometrs_times: null
            }
        };
        this.methods = {
             getLocalDates(date){
                if(date){
                  var stillUtc = moment.utc(date).toDate();
                  var local = moment(stillUtc).local().format('DD-MM-YYYY HH:MM');
                  return local;
                }
            },
             renovarr(){
                var km_timesssss = null;
                if(this.kilometrs_times)
                {
                    km_timesssss =parseInt(this.kilometrs_times)
                }
                var man = {
                    'vehicle_id': this.selectedVehicle.vehicle_id, 
                    'maintenance_type_id': this.selectedMaintType.type_maintenance_id , 
                    'obs': this.obs, 
                    'select_turno': this.select_turno, 
                    'otm_id': this.otm_id, 
                    'new_kilometrs': this.new_kilometrs,
                    'mainte_date': this.mainte_date,
                    'kilometrs_maintenence': this.kilometrs_maintenence,
                    'kilometrs_times': km_timesssss
                    }

                 MaintenanceService.renovarMaint(man, this.$route.params.maint_id).then((response) => {
                        if(response.data.status == 'OK'){
                            this.$store.dispatch('form/result', {isError: false, message: response.data.message});
                            this.goTo('gestion-mantenimiento');
                        }

                        if(response.data.status == 'ERROR')
                            this.$store.dispatch('form/result', {isError: true, message: response.data.message});

                        
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
            },
            newMan(){ 
                var km_timesssss = null;
                if(this.kilometrs_times) {
                    km_timesssss =parseInt(this.kilometrs_times)
                }

                var man = {'vehicle_id': this.selectedVehicle.vehicle_id, 'maintenance_type_id': this.selectedMaintType.type_maintenance_id , 'obs': this.obs, 'select_turno': this.select_turno, 'otm_id': this.otm_id, 'new_kilometrs': this.new_kilometrs, 'mainte_date': this.mainte_date,
                    'kilometrs_maintenence': this.kilometrs_maintenence,
                    'kilometrs_times': km_timesssss}

                 MaintenanceService.newMaintenance(man).then((response) => {
                        if(response.data.status == 'OK'){
                            this.$store.dispatch('form/result', {isError: false, message: response.data.message});
                            this.goTo('gestion-mantenimiento');
                        }

                        if(response.data.status == 'ERROR')
                            this.$store.dispatch('form/result', {isError: true, message: response.data.message});

                        
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
            },
            clearForm(){
                this.selectedVehicle= null;
                this.new_kilometrs = null;
            },
            searchVehicleMultiple(event){
                var filteredResultVehicle = [];
                if (event.query !== null && event.query.trim() !== '' && event.query.length > 0) {
                    event.query = event.query.toLowerCase();
                    for (var i = 0; i < this.all_vehicle.length; i++) {
                        if (this.all_vehicle[i].plate.toLowerCase().replace(/\s/g, '').trim().includes(event.query.replace(/\s/g, '').trim())  
                        ) {
                            filteredResultVehicle.push(this.all_vehicle[i])
                        }
                    }
                    this.filteredVehicleMultiple = filteredResultVehicle;
                } else {
                    return [];
                }   
            },
            searchMaintTypeMultiple(event){
                var filteredResult = [];
                if (event.query !== null && event.query.trim() !== '' && event.query.length > 0) {
                    event.query = event.query.toLowerCase();
                    for (var i = 0; i < this.all_maint_type.length; i++) {
                        if (this.all_maint_type[i].name.toLowerCase().replace(/\s/g, '').trim().includes(event.query.replace(/\s/g, '').trim())  
                        ) {
                            filteredResult.push(this.all_maint_type[i])
                        }
                    }
                    this.filteredMaintTypeMultiple = filteredResult;
                } else {
                    return [];
                }   
            },
            getAllVehicle(){
                 VehicleService.getAll().then((response) => {
                        this.all_vehicle= response.data;


                        if (this.$route.params.id_vehicle) {
                           this.selectedVehicle = this.all_vehicle.find( v => v.vehicle_id === parseInt(this.$route.params.id_vehicle) ); 
                        }
                        

                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
            },
            getAllMaintenanceType(){
                 MaintenanceService.getAllType().then((response) => {
                        this.all_maint_type= response.data;
                        if (this.$route.params.id_type) {
                           this.selectedMaintType = this.all_maint_type.find( v => v.type_maintenance_id === parseInt(this.$route.params.id_type) ); 
                        }
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
            },
            isInGroups: function (groupName) {
                for (var i = 0; i < this.groups.length; i++) {
                        if (this.groups[i].name === groupName) {
                            return true;
                        }  
                }
                return false;
            },
            goTo: function (route) {
                this.$router.push({name: route});
            },
        };
        this.computed = {
             ...mapGetters({
                groups: 'auth/groups',
                error: 'form/error-states'
            })
        };
        this.watch = {
            selectedMaintType(newValue){
                console.log(newValue)
                if(newValue){
                    if(this.$route.params.km_times){
                        this.kilometrs_times = this.$route.params.km_times;
                    }else{
                        this.kilometrs_times = newValue.km_next;
                    }
                    
                }
            }
        };

        this.props = [];
    }).apply(NewMaintenanceVehicle);

    export default NewMaintenanceVehicle
</script>
