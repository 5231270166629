import axios from "axios";

export default {
    getAll() {
        const url = `${process.env.VUE_APP_API_CORE_URL}/vehicles`;
        return axios.get(url);
    },
    get(id) {
        const url = `${process.env.VUE_APP_API_CORE_URL}/vehicle`;
        return axios.get(url, id)
    },

    get_by_id(id) {
        const url = `${process.env.VUE_APP_API_CORE_URL}/vehicle`;
        let data = {vehicle_id: id}
        return axios.post(url, data)
    },

    getAllStatus(days_alert_renew, days_critic_renew, days_prox_renew) {
        const url = `${process.env.VUE_APP_API_CORE_URL}/vehicles-status`;
        let data = {days_alert_renew: days_alert_renew, days_critic_renew: days_critic_renew, days_prox_renew: days_prox_renew}
        return axios.post(url, data)
    },

    newDOCS(soap, per_cir, rev_tec) {
        const url = `${process.env.VUE_APP_API_CORE_URL}/vehicles-update-docs`;
        let data = {soap: soap, per_cir: per_cir, rev_tec: rev_tec}
        return axios.post(url, data)
    },

    useVehicle(vehicle, kilometers, tray) {
        const url = `${process.env.VUE_APP_API_CORE_URL}/vehicles`;
        let data = {operation: 'use-vehicle', vehicle: vehicle, kilometers: kilometers, tray: tray}
        return axios.post(url, data)
    },

    newKilometers(vehicle, kilometers) {
        const url = `${process.env.VUE_APP_API_CORE_URL}/vehicles`;
        let data = {operation: 'update-vehicle-kilometers', vehicle: vehicle, kilometers: kilometers}
        return axios.post(url, data)
    },


    activate(vehicle) {
        const url = `${process.env.VUE_APP_API_CORE_URL}/vehicles`;
        let data = {operation: 'activate-vehicle', vehicle: vehicle}
        return axios.post(url, data)
    },


    deactivate(vehicle) {
        const url = `${process.env.VUE_APP_API_CORE_URL}/vehicles`;
        let data = {operation: 'deactivate-vehicle', vehicle: vehicle}
        return axios.post(url, data)
    },


    historyKilometers(vehicle) {
        const url = `${process.env.VUE_APP_API_MAINT_URL}/getallkmhistoryvehicle`;
        let data = {vehicle_id: vehicle}
        return axios.post(url, data)
    },

    allMaintenanceVehicle(vehicle) {
        const url = `${process.env.VUE_APP_API_MAINT_URL}/getallmaintenancevehicle`;
        let data = {vehicle_id: vehicle}
        return axios.post(url, data)
    },

    allVehicleMaintenance() {
        const url = `${process.env.VUE_APP_API_MAINT_URL}/getallVehiclemaintenance`;
        let data = {vehicle_id: null}
        return axios.post(url, data)
    },



}
