<template id="home">
    <div class="card p-fluid">

    <h5 class="text-black">MANTENEMIENTOS TODOS VEHICULO <buttonp class="w-1 p-button-warning" label="+" @click.prevent="goToAdd()"/></h5>

    <div class="grid m-3">
        <!--div class="col-12 md:col-12 lg:col-4">
        </div> 
        <div class="col-12 md:col-12 lg:col-4">
            <autoComplete id="vehicle_selector_id" v-model="selectedVehicle" field="plate" :suggestions="filteredVehicleMultiple" @complete="searchVehicleMultiple($event)" placeholder="Seleccione vehiculo"></autoComplete>
        </div>
        <div class="col-12 md:col-12 lg:col-4">
           
        </div> 

        <div class="col-12 md:col-12 lg:col-4">
           
        </di> 
        <div class="col-12 md:col-12 lg:col-4" v-if="selectedVehicle != null">
            <p> Ultima Medicion: <strong>{{getLocalDate(selectedVehicle.last_medition_km_date)}}</strong></p>
            <p> Kilometraje Actual: <strong>{{selectedVehicle.kilometers}} KM</strong></p>
        </div> 

        <div class="col-12 md:col-12 lg:col-3"  v-if="selectedVehicle != null">
            <buttonp class="w-6 mt-3 p-button-warning" label="BUSCAR" @click.prevent="buscar()"/>

        </div--> 
        <div class="col-12 md:col-12 lg:col-12">
           TOTALES - <span v-if="kms.length > 0">{{kms.length}} Vehiculos Evaluados</span>
        </div> 
        <div v-if="totals" class="col-12 md:col-4 lg:col-4">
           <strong>Alertas:</strong>  <Tag class="mr-2"  severity="warning" :value="totals.alert"></Tag>
        </div> 
        <div v-if="totals" class="col-12 md:col-4 lg:col-4">
           <strong>Criticos:</strong> <Tag severity="danger" :value="totals.critic"></Tag>
        </div> 
        <div v-if="totals" class="col-12 md:col-4 lg:col-4">
           <strong>Validos:</strong> <Tag class="mr-2" severity="success" :value="totals.valid"></Tag>
        </div> 


        <div class="col-12 md:col-12 lg:col-12" v-if="kms.length > 0">
            <DataTable :value="kms" responsiveLayout="scroll">
                <!--Column field="maintenance_id" header="ID"></Column-->
                <Column field="vehicle" header="Patente">
                    <template #body="slotProps">
                        {{slotProps.data.vehicle}}
                    </template>
                </Column>
                <Column field="alerta" header="En Alerta"></Column>
                <Column field="critico" header="Criticos"></Column>
                <Column field="valido" header="Validos"></Column>
                <Column header="ACCIONES">
                    <template #body="slotProps">
                        <buttonp  @click.prevent="selectDetalle(slotProps.data.detalle, slotProps.data.vehicle)" label="VER DETALLE" class="ml-1 p-button-sm p-button-warning" icon="pi pi-search-plus"/>
                     </template>
                </Column>
            </DataTable>

        </div>
        <div class="col-12 md:col-12 lg:col-12" v-else>
            SIN DATOS
        </div>
    </div>

     <Dialog :header="'Detalle Mantenimientos Vehiculo'" v-model:visible="displayDetail" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '70vw'}">
            <div class="p-grid p-nogutter p-d-flex p-justify-center p-ai-center" style="height: 100%; min-height: 100vh">
                
                <DataTable :value="selectedDetail" responsiveLayout="scroll">
                    <!--Column field="maintenance_id" header="ID"></Column-->
                    <Column field="maintenance_date" header="FECHA INICIO">
                        <template #body="slotProps">
                            {{getLocalDate(slotProps.data.maintenance_date)}}
                        </template>
                    </Column>
                    <Column field="type_name" header="Tipo Mantenimiento"></Column>
                    <!--Column field="maint_km_vehicle" header="KM MANTENCION"></Column-->
                    <!--Column field="vehicle_kilometrs" header="KM ACTUAL"></Column-->
                    <!--Column field="km_day_average" header="PROMEDIO DIARIO KM"></Column-->
                    <Column field="dias_vencimiento" header="Dias Vencimiento">
                        <template #body="slotProps">
                            <statustag :status="slotProps.data.dias_vencimiento" :alert="slotProps.data.alert_days" :critic="slotProps.data.critic_days"></statustag>
                        </template>
                    </Column>
                    <Column field="next_date_maintenance" header="FECHA PROXIMA">
                        <template #body="slotProps">
                            {{getLocalDate(slotProps.data.next_date_maintenance)}}
                        </template>
                    </Column>
                    <Column field="next_km_maintenance" header="KM PROXIMO"></Column>
                    <Column header="ACCIONES">
                        <template #body="slotProps">
                            <buttonp  @click.prevent="renovar(slotProps.data.vehicle_id, slotProps.data.maintenance_type_id, slotProps.data.maintenance_id, slotProps.data.kilometers_times)" label="Renovar" class="ml-1 p-button-sm p-button-warning" icon="pi pi-exclamation-circle"/>
                         </template>
                    </Column>
                </DataTable>
            </div>
            <template #footer>
                <buttonp label="Cerrar" icon="pi pi-times" @click="displayDetail = false;" autofocus />
            </template>
        </Dialog>

 
    <br /><br /><br /><br /><br /><br /><br />

    </div>
</template>


<script>
    import VehicleService from '@/service/VehicleService';
    import {mapGetters} from 'vuex';
     import moment from "moment";
     import Statustag from '@/components/StatusTag';
    
    let MaintenanceVehicle = {};
    (function () {
        this.name = 'maintenance-vehicle-page';
        // let _self = null;

        this.created = function () {
            this.buscar();
        };

        this.mounted  = function () {
            window.scrollTo(0, 0);
        };

        this.data = function () {
            return {
               selectedVehicle: null,
               all_vehicle: [],
               filteredVehicleMultiple: [],
               kms: [],
               displayDetail: false,
               selectedDetail: [],
               selectedPlate: [],
               totals: null,
            }

        };
        this.methods = {
            selectDetalle(details, plate){
                this.selectedPlate = plate;
                this.selectedDetail = details;
                this.displayDetail = true;
            },
            renovar(vi, ti, mi, km_times){
                this.$router.push({name: 'nuevo-mantenimiento', params: { id_type: ti, id_vehicle:  vi, maint_id: mi, km_times: km_times} });
            },
            getLocalDate(date){
                if(date){
                  var stillUtc = moment.utc(date).toDate();
                  var local = moment(stillUtc).local().format('DD-MM-YYYY');
                  return local;
                }
            },
            goToAdd(){
                  this.$router.push({name: 'nuevo-mantenimiento'});
            },
            clearForm(){
                this.selectedVehicle= null;
                this.new_kilometrs = null;
            },
            buscar(){
                 //falta validacion vehiculo not null, kilometraje igual, mayor 0.

                VehicleService.allVehicleMaintenance().then((response) => {
                    this.kms = response.data.vehicle_report;
                    this.totals = response.data.totals
                    //this.$store.dispatch('form/result', {isError: false, message: response.data.message}
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });

            },
            isInGroups: function (groupName) {
                for (var i = 0; i < this.groups.length; i++) {
                        if (this.groups[i].name === groupName) {
                            return true;
                        }  
                }
                return false;
            },
            goTo: function (route) {
                this.$router.push({name: route});
            },
        };
        this.components = {Statustag};
        this.computed = {
             ...mapGetters({
                groups: 'auth/groups',
                error: 'form/error-states'
            })
        };
        this.watch = {

        };

        this.props = [];
    }).apply(MaintenanceVehicle);

    export default MaintenanceVehicle
</script>
