<template id="home">
    <div class="card p-fluid">

    <h5 class="text-black">HISTORIAL KILOMETRAJES VEHICULO</h5>

    <div class="grid m-3">
        <div class="col-12 md:col-12 lg:col-4">
        </div> 
        <div class="col-12 md:col-12 lg:col-4">
            <autoComplete id="vehicle_selector_id" v-model="selectedVehicle" field="plate" :suggestions="filteredVehicleMultiple" @complete="searchVehicleMultiple($event)" placeholder="Seleccione vehiculo"></autoComplete>
        </div>
        <div class="col-12 md:col-12 lg:col-4">
           
        </div> 

        <div class="col-12 md:col-12 lg:col-4">
           
        </div> 
        <div class="col-12 md:col-12 lg:col-4" v-if="selectedVehicle != null">
            <p> Ultima Medicion: {{selectedVehicle.last_medition_km_date}}</p>
            <p> Kilometraje Actual: {{selectedVehicle.kilometers}} KM</p>
        </div> 

        <!--div class="col-12 md:col-12 lg:col-3"  v-if="selectedVehicle != null">
            <buttonp class="w-6 mt-3 p-button-warning" label="BUSCAR" @click.prevent="buscar()"/>

        </div--> 
        <div class="col-12 md:col-12 lg:col-4">
           
        </div> 

        <div class="col-12 md:col-12 lg:col-12" v-if="kms.length > 0">
            <DataTable :value="kms" responsiveLayout="scroll">
                <Column field="kilometers_id" header="ID HISTORAIL"></Column>
                <Column field="admission_date" header="Fecha ingreso">
                    <template #body="slotProps">
                            {{getLocalDate(slotProps.data.admission_date)}}
                    </template>
                </Column>
                <Column field="new_km" header="NUEVO KM"></Column>
                <Column field="old_km" header="ANTIGUO KM"></Column>
                <!--Column field="updater_id" header="ACTUALIZADOR"></Column-->
            </DataTable>
        </div>
        <div class="col-12 md:col-12 lg:col-12" v-else>
            SIN DATOS
        </div>
    </div>
 
    <br /><br /><br /><br /><br /><br /><br />

    </div>
</template>


<script>
    import VehicleService from '@/service/VehicleService';
    import {mapGetters} from 'vuex';
    import moment from "moment";
    
    let KmHistoryVehicle = {};
    (function () {
        this.name = 'update-kilometers-vehicle-page';
        // let _self = null;

        this.created = function () {
            this.getAllVehicle();
        };

        this.mounted  = function () {
            window.scrollTo(0, 0);
        };

        this.data = function () {
            return {
               selectedVehicle: null,
               all_vehicle: [],
               filteredVehicleMultiple: [],
               kms: [],
            }
        };
        this.methods = {
             getLocalDate(date){
                if(date){
                  var stillUtc = moment.utc(date).toDate();
                  var local = moment(stillUtc).local().format('DD-MM-YYYY');
                  return local;
                }
            },
            clearForm(){
                this.selectedVehicle= null;
                this.new_kilometrs = null;
            },
            buscar(){
                 //falta validacion vehiculo not null, kilometraje igual, mayor 0.
                console.log(this.selectedVehicle.vehicle_id);
                VehicleService.historyKilometers(this.selectedVehicle.vehicle_id).then((response) => {
                    this.kms = response.data;
               
                    //this.$store.dispatch('form/result', {isError: false, message: response.data.message}
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });

            },

            searchVehicleMultiple(event){
                var filteredResultVehicle = [];
                if (event.query !== null && event.query.trim() !== '' && event.query.length > 0) {
                    event.query = event.query.toLowerCase();
                    for (var i = 0; i < this.all_vehicle.length; i++) {
                        if (this.all_vehicle[i].plate.toLowerCase().replace(/\s/g, '').trim().includes(event.query.replace(/\s/g, '').trim())  
                        ) {
                            filteredResultVehicle.push(this.all_vehicle[i])
                        }
                    }
                    this.filteredVehicleMultiple = filteredResultVehicle;
                } else {
                    return [];
                }   
            },
            getAllVehicle(){
                 VehicleService.getAll().then((response) => {
                        this.all_vehicle= response.data;
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
            },
            isInGroups: function (groupName) {
                for (var i = 0; i < this.groups.length; i++) {
                        if (this.groups[i].name === groupName) {
                            return true;
                        }  
                }
                return false;
            },
            goTo: function (route) {
                this.$router.push({name: route});
            },
        };
        this.computed = {
             ...mapGetters({
                groups: 'auth/groups',
                error: 'form/error-states'
            })
        };
        this.watch = {
            selectedVehicle(newValue){
                if(newValue)
                    this.buscar();
            }
        };

        this.props = [];
    }).apply(KmHistoryVehicle);

    export default KmHistoryVehicle
</script>
