import axios from "axios";

export default {

	getAllType() {
        const url = `${process.env.VUE_APP_API_MAINT_URL}/getallmaintenancetype`;
        return axios.get(url)
    },

    newMaintenance(m) {
        const url = `${process.env.VUE_APP_API_MAINT_URL}/newmaintenance`;
        let data = {maint: m}
        return axios.post(url, data)
    },


    renovarMaint(m, id) {
        const url = `${process.env.VUE_APP_API_MAINT_URL}/renewmaintenance`;
        let data = {maint: m, maint_id: id}
        return axios.post(url, data)
    },


}