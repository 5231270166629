<template id="home">
    <div>

<h5 class="text-black">Gestion Mantenimiento</h5>

    <div class="grid">


            <!--div class="col-12 md:col-12 lg:col-6">
               <buttonp class="w-6 mt-3 p-button-warning" label="Actualizar Kilometrajes" @click.prevent="goTo('update-kilometer-vehicle')"/>
            </div-->


             <div class="col-12 md:col-12 lg:col-3">
                <span class="ml-1 is-bold">Dias Criticos Previos Renovacion</span> <br/>
                <InputText class="mt-3 ml-6 mr-3" id="critic_renovacion" type="text" v-model="days_critic_renew"/>
            </div>

             <div class="col-12 md:col-12 lg:col-3">
                <span class="ml-1">Dias Alerta Renovacion</span> <br/>
                <InputText class="mt-3 ml-6 mr-3" id="alert_renovacion" type="text" v-model="days_alert_renew"/>
            </div>

            <div class="col-12 md:col-12 lg:col-3">
                <span class="ml-1">Dias Proxima Renovacion</span> <br/>
                <InputText class="mt-3 ml-6 mr-3" id="prox_renovacion" type="text" v-model="days_prox_renew"/>
            </div>


            <div class="col-12 md:col-12 lg:col-3">
                <span class="ml-1">Obtener Estado Flota</span> <br/>
                <buttonp class="w-6 mt-3 p-button-warning" label="Ver" @click.prevent="getAllVehciles()"/>
            </div>

                <div class="mt-4 col-12 md:col-2 lg:col-2">
                    <p class="text-black-800 bold">DOCUMENTO</p>
                </div>

                <div class="mt-4  col-12 md:col-2 lg:col-2">
                    <p class="text-yellow-500">Indeterminado</p>
                </div>
                <div class=" mt-4 col-12 md:col-2 lg:col-2">
                    <p class="text-green-500">OK</p>
                </div>
               <div class="mt-4  ol-12 md:col-2 lg:col-2">
                    <p class="text-orange-500">ALERTA</p>
               </div>
               <div class=" mt-4 col-12 md:col-2 lg:col-2">
                    <p class="text-yellow-800">CRITICOS</p>
               </div>
               <div class="mt-4  col-12 md:col-2 lg:col-2">
                    <p class="text-orange-800">EXPIRADOS</p>
               </div>

                <div class="col-12 md:col-2 lg:col-2">
                    Certificado Circulacion
                </div>

                <div class="col-12 md:col-2 lg:col-2">
                    {{totals.cer_permit_inderter.length}}
                </div>
                <div class="col-12 md:col-2 lg:col-2">
                    {{totals.cer_permit_ok.length}}
                </div>
               <div class="col-12 md:col-2 lg:col-2">
                    {{totals.cer_permit_alert.length}}
               </div>
               <div class="col-12 md:col-2 lg:col-2">
                    {{totals.cer_permit_critic.length}}
               </div>
               <div class="col-12 md:col-2 lg:col-2">
                    {{totals.cer_permit_exipred.length}}
               </div>


                 <div class="col-12 md:col-2 lg:col-2">
                    SOAP
                </div>
               <div class="col-12 md:col-2 lg:col-2">
                {{totals.soap_inderter.length}}
               </div>
               <div class="col-12 md:col-2 lg:col-2">
                 {{totals.soap_ok.length}}
               </div>
               <div class="col-12 md:col-2 lg:col-2">
                 {{totals.soap_alert.length}}
               </div>
               <div class="col-12 md:col-2 lg:col-2">
                 {{totals.soap_critic.length}}
               </div>
               <div class="col-12 md:col-2 lg:col-2">
                {{totals.soap_exipred.length}}
               </div>



                 <div class="col-12 md:col-2 lg:col-2">
                    Revision Tecnica
                </div>
               <div class="col-12 md:col-2 lg:col-2">
                 {{totals.review_inderter.length}}
               </div>
               <div class="col-12 md:col-2 lg:col-2">
                 {{totals.review_ok.length}}
               </div>
               <div class="col-12 md:col-2 lg:col-2">
                 {{totals.review_alert.length}}
               </div>
               <div class="col-12 md:col-2 lg:col-2">
                {{totals.review_critic.length}}
               </div>
               <div class="col-12 md:col-2 lg:col-2">
                 {{totals.review_exipred.length}}
               </div>


            <div class="col-12 md:col-12 lg:col-12">
                    <DataTable :value="report" :paginator="false" class="p-datatable-sm" :rows="10"
                    dataKey="id" :rowHover="true" v-model:selection="selectedReportRow" v-model:filters="filters" filterDisplay="menu" :loading="loading"
                    :globalFilterFields="['plate', 'identifier', 'vehicle_type', 'sub_type', 'brand', 'vehicle_model', 'vehicle_year']" responsiveLayout="scroll">
                    <template #header>
                <div class="flex justify-content-center align-items-center">
                    <h5 class="mr-1">Vehiculos</h5>
                    <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText v-model="filters['global'].value" placeholder="Palabra Clave" />
                    </span>
                </div>
            </template>
                    <template #empty>
                        No se encontraron datos.
                    </template>
                    <template #loading>
                        Cargando datos. Espere...
                    </template>
                    <!--Column selectionMode="multiple" headerStyle="width: 3rem"></Column-->
                    <Column field="Total_calculate_status" header="Estado" sortable style="min-width: 14rem">
                        <template #body="{data}">
                            <span class="text-green-500" v-if="data.Total_calculate_status == 'OK'">{{data.Total_calculate_status}}</span>
                            <span class="text-orange-500" v-if="data.Total_calculate_status == 'ALERTA'">{{data.Total_calculate_status}}</span>
                            <span class="text-yellow-500" v-if="data.Total_calculate_status == 'INDETERMINADO'">{{data.Total_calculate_status}}</span>
                        </template>
                    </Column> 
                    <Column field="identifier" header="Vehiculo" sortable style="min-width: 14rem">
                        <template #body="{data}">
                            <span class="text-xs">{{data.identifier}} - {{data.plate}} - {{data.vehicle_type}} {{data.sub_type}} - {{data.brand}} - {{data.vehicle_model}} - {{data.vehicle_year}}</span>
                        </template>
                    </Column>
                    <!--Column field="plate" header="Patente" sortable filterMatchMode="plate" style="min-width: 14rem">
                        <template #body="{data}">
                            
                            <span class="image-text">{{data.plate}}</span>
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar pot patente"/>
                        </template>
                    </Column>
                    <Column field="brand" header="Marca" sortable filterMatchMode="brand" style="min-width: 14rem">
                        <template #body="{data}">
          
                            <span class="image-text">{{data.brand}}</span>
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar pot marca"/>
                        </template>
                    </Column> 
                    <Column field="vehicle_model" header="Modelo" sortable filterMatchMode="vehicle_model" style="min-width: 14rem">
                        <template #body="{data}">
                         
                            <span class="image-text">{{data.vehicle_model}}</span>
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar pot modelo"/>
                        </template>
                    </Column> 
                    <Column field="vehicle_year" header="Año" sortable filterMatchMode="vehicle_year" style="min-width: 14rem">
                        <template #body="{data}">
                           
                            <span class="image-text">{{data.vehicle_year}}</span>
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar pot año"/>
                        </template>
                    </Column> 
                     <Column field="vehicle_type" header="Tipo" sortable filterMatchMode="vehicle_type" style="min-width: 14rem">
                        <template #body="{data}">
                      
                            <span class="image-text">{{data.vehicle_type}}</span>
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar pot tipo"/>
                        </template>
                    </Column> 
                    <Column field="sub_type" header="Subtipo" sortable filterMatchMode="sub_type" style="min-width: 14rem">
                        <template #body="{data}">
                            <span class="image-text">{{data.sub_type}}</span>
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar pot subtipo"/>
                        </template>
                    </Column--> 
                    <Column field="kilometers" header="Kilometraje" sortable style="min-width: 14rem">
                        <template #body="{data}">
                            {{data.kilometers}} KM
                        </template>
                    </Column> 
                    <!--Column field="weekly_average_km" header="Kilometraje Semanal" sortable style="min-width: 14rem"></Column--> 

                <Column field="last_medition_km_date" header="Ultima Medicion KM" sortable dataType="date" style="min-width: 8rem">
                        <template #body="{data}">
                            {{formatDate(data.last_medition_km_date)}}
                        </template>
                    </Column>
            
            <Column field="soap" header="SOAP" sortable dataType="date" style="min-width: 8rem">
                        <template #body="{data}">
                            <span :class="{'text-orange-600': data.calculate_status_soap == 'EXPIRED', 'text-green-600': data.calculate_status_soap == 'OK', 'text-yellow-600': data.calculate_status_soap == 'ALERTA', 'text-yellow-800': data.calculate_status_soap == 'CRITIC'}">{{formatDate(data.soap)}}</span>
                        </template>
                    </Column>
                    <Column field="circulation_permit" header="Permiso Circulacion" sortable dataType="date" style="min-width: 8rem">
                        <template #body="{data}">
                            <span :class="{'text-orange-600': data.calculate_status_circulation_permit == 'EXPIRED', 'text-green-600': data.calculate_status_circulation_permit == 'OK', 'text-yellow-600': data.calculate_status_circulation_permit == 'ALERTA', 'text-yellow-800': data.calculate_status_circulation_permit == 'CRITIC'}"> 
                                {{formatDate(data.circulation_permit)}}
                            </span>
                        </template>
                    </Column>

                     <Column field="technical_review" header="Revision Tecnica" sortable dataType="date" style="min-width: 8rem">
                        <template #body="{data}">
                            <span :class="{'text-orange-600': data.calculate_status_technical_review == 'EXPIRED', 'text-green-600': data.calculate_status_technical_review == 'OK', 'text-yellow-600': data.calculate_status_technical_review == 'ALERTA', 'text-yellow-800': data.calculate_status_technical_review == 'CRITIC'}"> {{formatDate(data.technical_review)}} </span>
                        </template>
                    </Column>

                </DataTable>
            </div> 

    </div>
        
        <br /><br /><br /><br /><br /><br /><br />

    </div>
</template>


<script>
    
    import {mapGetters} from 'vuex';
    import {FilterMatchMode,FilterOperator} from 'primevue/api';
    import moment from "moment";
    import VehicleService from '@/service/VehicleService';
    
    let GeneralVehicle = {};
    (function () {
        this.name = 'gestion-gauss-control-page';
        // let _self = null;

        this.created = function () {
            this.getAllVehciles();
        };

        this.mounted  = function () {
            window.scrollTo(0, 0);
        };

        this.data = function () {
            return {
                totals : {'cer_permit_inderter':[], 'cer_permit_ok':[], 'cer_permit_alert':[], 'cer_permit_critic':[], 'cer_permit_exipred':[], 'soap_inderter':[], 'soap_ok':[], 'soap_alert':[], 'soap_critic':[], 'soap_exipred':[], 'review_inderter':[], 'review_ok':[], 'review_alert':[], 'review_critic':[], 'review_exipred':[]},
                days_alert_renew: 30,
                days_critic_renew: 10,
                days_prox_renew: 365,
                selectedReportRow: null,
                report: [],
                filters: {
                    'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                    'vehicle_id': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                    'plate': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                    'brand': {value: null, matchMode: FilterMatchMode.IN},
                    'vehicle_model': {value: null, matchMode: FilterMatchMode.IN},
                    'vehicle_type': {value: null, matchMode: FilterMatchMode.IN},
                    'sub_type': {value: null, matchMode: FilterMatchMode.IN},
                    'vehicle_year': {value: null, matchMode: FilterMatchMode.IN},
                    'kilometers': {value: null, matchMode: FilterMatchMode.IN},
                    'last_medition_km_date': {value: null, matchMode: FilterMatchMode.IN},
                    'weekly_average_km': {value: null, matchMode: FilterMatchMode.IN},
                    'soap': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
                    'circulation_permit': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
                    'technical_review': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
                },
                loading: true,
            }
        };
        this.methods = {
            getAllVehciles(){
                this.report = [];
                 VehicleService.getAllStatus(this.days_alert_renew, this.days_critic_renew, this.days_prox_renew).then((response) => {
                        this.report = response.data.vehicles;
                        this.totals = response.data.totals
                        this.loading = false;
                }).catch((error) => {
                    console.log(error);
                    this.loading = false;
                    this.$store.dispatch('form/submit', false);
                });
            },
            formatDate(date) {
                    if(date){
                  var stillUtc = moment.utc(date).toDate();
                  var local = moment(stillUtc).local().format('DD-MM-YYYY');
                  return local;
                }
            },
            isInGroups: function (groupName) {
                for (var i = 0; i < this.groups.length; i++) {
                        if (this.groups[i].name === groupName) {
                            return true;
                        }  
                }
                return false;
            },
            goTo: function (route) {
                this.$router.push({name: route});
            },
        };
        this.computed = {
             ...mapGetters({
                groups: 'auth/groups'
            })
        };
        this.watch = {

        };
        
        this.props = [];
    }).apply(GeneralVehicle);

    export default GeneralVehicle
</script>
