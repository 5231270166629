import { createWebHistory, createRouter } from "vue-router";
import Login from '../views/LoginPage';
import Home from '../views/HomePage';
import UpdateKilometersVehicle from '../views/UpdateKilometersVehicle';
import GeneralVehicle from '../views/GeneralVehicle';
import KmHistoryVehicle from '../views/KmHistoryVehicle';
import MaintenenceVehicle from '../views/MaintenanceVehicle';
import MaintenenceAllVehicle from '../views/MaintenenceAllVehicle'; 
import NewMaintenenceVehicle from '../views/NewMaintenanceVehicle';

const routes = [
  {
        path: '/',
        name: 'login',
        component: Login,
        meta: {
            requiresAuth: false,
            module: 'authentication'
        }
    },
    {
        path: '/home',
        alias: ['/home'],
        name: 'home',
        component: Home,
        meta: {
            requiresAuth: true,
            module: 'home'
        }
    },

    {
        path: '/gestion-mantenimiento',
        alias: ['/gestion-mantenimiento'],
        name: 'gestion-mantenimiento',
        component: MaintenenceVehicle,
        meta: {
            requiresAuth: true,
            module: 'home'
        }
    },
    {
        path: '/gestion-all-vehicle-mantenimiento',
        alias: ['/gestion-all-vehicle-mantenimiento'],
        name: 'gestion-all-vehicle-mantenimiento',
        component: MaintenenceAllVehicle,
        meta: {
            requiresAuth: true,
            module: 'home'
        }
    },
    {
        path: '/nuevo-mantenimiento/:id_type?/:id_vehicle?/:maint_id?/:km_times?',
        alias: ['/nuevo-mantenimiento'],
        name: 'nuevo-mantenimiento',
        component: NewMaintenenceVehicle,
        meta: {
            requiresAuth: true,
            module: 'home'
        }
    },

    {
        path: '/kilometer-history-vehicle',
        alias: ['/kilometer-history-vehicle'],
        name: 'kilometer-history-vehicle',
        component: KmHistoryVehicle,
        meta: {
            requiresAuth: true,
            module: 'home'
        }
    },
    {
        path: '/update-kilometer-vehicle',
        alias: ['/update-kilometer-vehicle'],
        name: 'update-kilometer-vehicle',
        component: UpdateKilometersVehicle,
        meta: {
            requiresAuth: true,
            module: 'home'
        }
    },
    {
        path: '/general-vehicle',
        alias: ['/general-vehicle'],
        name: 'general-vehicle',
        component: GeneralVehicle,
        meta: {
            requiresAuth: true,
            module: 'home'
        }
    },

    {
        name: 'logout',
        path: '/logout',
        redirect: to => {
            console.log(to);
            return {name: 'login'};
        },
        meta: {
            requiresAuth: false,
            module: 'authentication'
        }
    },

]

const router = createRouter({
  history: createWebHistory(),
  routes,
});

routes.map((route) => {
    if (!route.meta) route.meta = {};
    if (route.meta.requiresAuth !== false)
        route.meta.requiresAuth = true;
});

export default router
