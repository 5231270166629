<template id="home">
    <div class="card p-fluid">

    <h3 class="text-black">Actualizacion de Kilometrajes</h3>

    <div class="grid m-3">
        <div class="col-12 md:col-12 lg:col-12">
        </div> 

        <div class="col-12 md:col-12 lg:col-12">

            <!--h5 class="text-black">Activar Vehiculo</h5>

            <autoComplete id="vehicle_selector_id" v-model="selectedVehicle" field="plate" :suggestions="filteredVehicleMultiple" @complete="searchVehicleMultiple($event)" placeholder="Seleccione vehiculo"></autoComplete>

            <buttonp class="w-6 mt-3 button-warning" :label="'Activar Vehiculo'" @click.prevent="activar()"/>

            <br /><br /-->


            <TabView>
                  <TabPanel v-for="type in vehicle_types" :key="type" :header="type">
                    <InputText placeholder="Buscar por patente"
           v-model="searchTerm[type]"
           @input="searchVehicleByType(type)" />

                <DataTable :value="filtered_vehicles_by_type[type]" @row-click="onRowSelected" selectionMode="single" :selection="selectedVehicleRow">
                      <Column field="plate" header="Vehículo"></Column>
                      <Column field="last_medition_km_date" header="Última Medición"></Column>
                      <Column field="kilometers" header="Kilometraje Actual"></Column>
                      <Column header="Nuevo Kilometraje">
                        <template v-slot:body="slotProps">
                          <div>
                            <InputText type="number" v-model="slotProps.data.new_kilometers" @blur="actualizar( slotProps.data)" />
                          </div>
                        </template>
                      </Column>
                    </DataTable>

              </TabPanel>
              <TabPanel header="Vehículos Inactivos">

               <InputText placeholder="Buscar por patente"
           v-model="searchTermInactive"
           @input="searchInactiveVehicles" />

                 <DataTable :value="filtered_inactive_vehicles">
                    <Column field="vehicle_type" header="Vehículo"></Column>
                    <Column field="plate" header="Vehículo"></Column>

                          <Column field="last_medition_km_date" header="Última Medición"></Column>
                          <Column field="kilometers" header="Kilometraje Actual"></Column>
                          <Column header="Acciones">
                              <template #body="slotProps">
                                <buttonp class=" mt-3 button-warning"  label="Activar" @click="activar(slotProps.data)" />
                              </template>
                            </Column>
                </DataTable>
              </TabPanel>
</TabView>

  


        </div>


    </div>
 
    <br /><br /><br /><br /><br /><br /><br />

    </div>
</template>


<script>
    

  
    import VehicleService from '@/service/VehicleService';

    import {mapGetters} from 'vuex';
    


    let UpdateKilometersVehicle = {};
    (function () {
        this.name = 'update-kilometers-vehicle-page';
        // let _self = null;

        this.created = function () {
            this.getAllVehicle();
};

        this.mounted  = function () {
            window.scrollTo(0, 0);
        };

        this.data = function () {
            return {
               selectedVehicle: null,
                searchTerm: {},
               filteredVehicleMultiple: [],
               lo_ingresado: [],
               new_kilometrs: null,
                all_vehicle: [],
                active_vehicles: [],
                inactive_vehicles: [],
                vehicles_by_type: {},
                 vehicle_types: [],
                 filtered_vehicles_by_type: {},
                 searchTermInactive: '',
                 filtered_inactive_vehicles: [],
                 selectedVehicleRow: null,
                        }
        };
        this.methods = {

        onRowSelected(event) {
            this.selectedVehicleRow = event.data; // o la propiedad que identifica la fila
        },

       searchVehicleByType(type) {
        if (this.searchTerm[type].trim() === '') {
          // Si no hay término de búsqueda, restablece a la lista original
          this.filtered_vehicles_by_type[type] = this.vehicles_by_type[type];
        } else {
          // Filtra los vehículos que coincidan con la patente ingresada
          this.filtered_vehicles_by_type[type] = this.vehicles_by_type[type].filter(vehicle =>
            vehicle.plate.toLowerCase().includes(this.searchTerm[type].toLowerCase())
          );
        }
      },

        searchInactiveVehicles() {
        if (this.searchTermInactive.trim() === '') {
          // Si no hay término de búsqueda, muestra todos los vehículos inactivos
          this.filtered_inactive_vehicles = this.inactive_vehicles;
        } else {
          // Filtra los vehículos inactivos que coincidan con la patente buscada
          this.filtered_inactive_vehicles = this.inactive_vehicles.filter(vehicle =>
            vehicle.plate.toLowerCase().includes(this.searchTermInactive.toLowerCase())
          );
        }
      },

            searchVehicleByPlate(event, type) {
    // Filtrar vehículos basándose en la búsqueda
    if (event.query.trim() === '') {
      // Si no hay búsqueda, mostrar todos los vehículos del tipo
      this.filtered_vehicles_by_type[type] = this.vehicles_by_type[type];
    } else {
      // Filtrar vehículos que coincidan con la patente
      this.filtered_vehicles_by_type[type] = this.vehicles_by_type[type].filter(vehicle =>
        vehicle.plate.toLowerCase().includes(event.query.toLowerCase())
      );
    }
  },
            clearForm(){
                this.selectedVehicle= null;
                this.new_kilometrs = null;
            },

            activar(vehicle){

                 //falta validacion vehiculo not null, kilometraje igual, mayor 0.
                VehicleService.activate(vehicle.vehicle_id).then((response) => {
                    if(response.data.status == 'OK'){
                      this.$store.dispatch('form/result', {isError: false, message: response.data.message});
                         this.getAllVehicle();
                            window.scrollTo(0, 0);
                    }else{
                      this.$store.dispatch('form/result', {isError: true, message: response.data.message});
                    }
                    //this.goTo('home');
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });

            },

            deactivate(vehicle){

                 //falta validacion vehiculo not null, kilometraje igual, mayor 0.
                VehicleService.deactivate(vehicle.vehicle_id).then((response) => {
                    if(response.data.status == 'OK'){
                      this.$store.dispatch('form/result', {isError: false, message: response.data.message});
                    }else{
                      this.$store.dispatch('form/result', {isError: true, message: response.data.message});
                    }
                    //this.goTo('home');
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });

            },


            actualizar(vehicle){

                if (!vehicle || vehicle.new_kilometers === vehicle.kilometers || vehicle.new_kilometers <= 0 || vehicle.new_kilometers ==='' || vehicle.new_kilometers === null) {
                    // Aquí deberías manejar el caso de que la validación falle
                    console.error("Validación fallida");
                    return;
                  }

                 //falta validacion vehiculo not null, kilometraje igual, mayor 0.
                VehicleService.newKilometers(vehicle.vehicle_id, parseInt(vehicle.new_kilometers)).then((response) => {
                    if(response.data.status == 'OK'){
                      this.$store.dispatch('form/result', {isError: false, message: response.data.message});
         
                            // Actualizar la lista de vehículos ingresados con el nuevo kilometraje
                              this.lo_ingresado.push({
                                vehicle: vehicle.plate,
                                new_kilometrs: vehicle.new_kilometers
                              });

                              // Actualizar el objeto de vehículo con el nuevo kilometraje y la fecha actual
                              vehicle.kilometers = vehicle.new_kilometers; // Actualizar el kilometraje
                              vehicle.last_medition_km_date = new Date().toISOString(); // Establecer la fecha actual como la última medición

                              // Limpiar el campo new_kilometers para el input
                              vehicle.new_kilometers = '';



                      this.clearForm();
                      document.getElementById("vehicle_selector_id").focus();
                    }else{
                      this.$store.dispatch('form/result', {isError: true, message: response.data.message});
                    }
                    //this.goTo('home');
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });

            },

            searchVehicleMultiple(event){
                var filteredResultVehicle = [];
                if (event.query !== null && event.query.trim() !== '' && event.query.length > 0) {
                    event.query = event.query.toLowerCase();
                    for (var i = 0; i < this.all_vehicle.length; i++) {
                        if (this.all_vehicle[i].plate.toLowerCase().replace(/\s/g, '').trim().includes(event.query.replace(/\s/g, '').trim())  
                        ) {
                            filteredResultVehicle.push(this.all_vehicle[i])
                        }
                    }
                    this.filteredVehicleMultiple = filteredResultVehicle;
                } else {
                    return [];
                }   
            },


getAllVehicle() {
  VehicleService.getAll().then((response) => {
     const vehicles = response.data.map(vehicle => ({
      ...vehicle,
      new_kilometers: '' // Inicializa la propiedad para el nuevo kilometraje
    }));

    // Filtrar los vehículos activos e inactivos
    this.inactive_vehicles = vehicles.filter(v => !v.active);
    this.active_vehicles = vehicles.filter(v => v.active);

    // Crear una estructura para clasificar los vehículos activos por tipo
    this.vehicles_by_type = this.active_vehicles.reduce((acc, vehicle) => {
      const type = vehicle.vehicle_type || 'Otros'; // Asegúrate de tener una categoría para tipos no definidos
      if (!acc[type]) {
        acc[type] = [];
      }
      acc[type].push(vehicle);
      return acc;
    }, {});

    // Inicializar los términos de búsqueda para cada tipo
    this.vehicle_types = Object.keys(this.vehicles_by_type);
    this.vehicle_types.forEach(type => {
      this.searchTerm[type] = ''; // Esto asume que tienes una propiedad searchTerm definida en tu data
    });

    // Inicializar las listas filtradas
    this.filtered_inactive_vehicles = this.inactive_vehicles;
    this.filtered_vehicles_by_type = JSON.parse(JSON.stringify(this.vehicles_by_type));
  }).catch((error) => {
    console.log(error);
    this.$store.dispatch('form/submit', false);
  });
},




            isInGroups: function (groupName) {
                for (var i = 0; i < this.groups.length; i++) {
                        if (this.groups[i].name === groupName) {
                            return true;
                        }  
                }
                return false;
            },
            goTo: function (route) {
                this.$router.push({name: route});
            },
        };
        this.computed = {
             ...mapGetters({
                groups: 'auth/groups',
                error: 'form/error-states'
            })
        };
        this.watch = {

        };

        this.props = [];
    }).apply(UpdateKilometersVehicle);

    export default UpdateKilometersVehicle
</script>
